import { Options, Vue } from "vue-class-component";
import {MaterialsRoutesEnum} from "@/modules/materials/router";
import {materialsService} from "@services/materials.service";
import {Material} from "@/model/api/Material";
import {FilterMatchMode} from "primevue/api";
import Table from "@components/AppTable/Table";

@Options({
  // beforeRouteEnter(to, from, next) {},
  beforeRouteLeave() {
    if (materialsService?.cancelPendingRequests) {
      materialsService.cancelPendingRequests();
    }
  },
})
export default class MaterialsPage extends Vue {

  dataset      : Array<Material> = null;

  filters = {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    }
  }

  newRoute = {
    name: MaterialsRoutesEnum.MATERIALS_DETAIL,
    params: { materialId: 'new' }
  }

  goToDetail(material: Material) {
    this.$router.push({
      name: MaterialsRoutesEnum.MATERIALS_DETAIL,
      params: { materialId: material.id }
    })
  }

  onDelete(material: Material) {
    this.$confirmMessage(this.$t(`material.messages.delete_confirm`))
        .then(r => {
          if (r) {
            this.deleteMaterial(material);
          }
        })
  }

  private deleteMaterial(material: Material) {
    this.$waitFor(
        async () => {
          await materialsService.deleteBySystem(material);
          this.refresh();
          this.$successMessage(this.$t(`material.messages.delete_success`))
        }
    )
  }

  refresh() {
    this.loadData();
  }

  private loadData() {
    return this.$waitFor(
        async () => {
          this.dataset = await materialsService.getAll();
        }
    )
  }

  mounted() {
    this.loadData();
  }

}